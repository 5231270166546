import React from "react"
import { Link } from "gatsby"
import "../styles/main.scss"

const ArticleLayout = ({ children, headerText, backTo, backToText }) => {
    return (
        <main>
            <span className="back"><Link to={ backTo }>{ backToText }</Link></span>
            <h1>{ headerText }</h1>
            { children }
        </main>
    )
}

export default ArticleLayout