import React from "react"
import { Link } from "gatsby"
import ArticleLayout from "../../components/article-layout"
import GamemodeLogo from "../../images/mtagm/gm-logo.png"
import LoginPanel from "../../images/mtagm/login-panel.png"
import Hud from "../../images/mtagm/hud.png"
import InfoPanel from "../../images/mtagm/info-pane.png"
import Scoreboard from "../../images/mtagm/scoreboard.png"
import VehicleConsole from "../../images/mtagm/vehicle-console.png"

const MultiTheftAutoGamemode = () => {
    return (
        <ArticleLayout headerText="Multi Theft Auto Gamemode" backTo="/projects" backToText="back to projects page">
            <img src={GamemodeLogo} alt="gamemode-logo" rel="noreferrer"/>
            <p>Simple gamemode</p>
            <section>
                <h2>Basic functionality</h2>
                <p>A lot of this stuff is obvious, please respect that form, it's only amatour features presentation</p>
                <b class="warning">list below is outdated, a lot of stuff is refactored and added, keep wait to update info about this gamemode.</b>
                <ol>
                    <li>
                        <a href={LoginPanel} target="_blank" rel="noreferrer">Account system</a>
                    </li>
                    <li>
                        <a href={Hud} target="_blank" rel="noreferrer">Simple and minimalist hud</a>
                    </li>
                    <li>
                        <a href={InfoPanel} target="_blank" rel="noreferrer">Info panel</a>
                        <ul>
                            <li>avatar like your skin, or settable by user on server page (working on it).</li>
                        </ul>
                    </li>
                    <li>
                        <a href={Scoreboard} target="_blank" rel="noreferrer">Scoreboard</a>
                        <ul>
                            <li>displays avatars, on rules like above</li>
                            <li>only some rows is showed, others is hidden, because all is divided for pages</li>
                        </ul>
                    </li>
                    <li>
                        <a href={VehicleConsole} target="_blank" rel="noreferrer">Vehicle console</a>
                        <ul>
                            <li>showing current radiostation</li>
                            <li>displays vehicle status - health, fuel, mileage.. etc.</li>
                            <li>informs about problems with engine, low fuel, and special troubles with vehicle</li>
                            <li>in/out animation</li>
                        </ul>
                    </li>
                    <li>
                        Real time - day is day, night is night, based on server date.
                    </li>
                </ol>
            </section>
        </ArticleLayout>
    )
}

export default MultiTheftAutoGamemode